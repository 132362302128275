import React, { useState, useEffect } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import {RiArrowGoBackFill} from 'react-icons/ri'
import { getMe } from '../features/authSlice'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ModalAddUsers from "../components/ModalAddUsers";
import { Link } from "react-router-dom";
import ModalEditUser from "../components/ModalEditUser";

const User = () => {
    const [data, setData] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [selectedUserId, setSelectedUserId] = useState('')

    const openAddModal = () => {
        setShowAddModal(true);
      };
    
      const closeAddModal = () => {
        setShowAddModal(false);
        getUsers();
      };

      const openEditModal = (userId) => {
        setSelectedUserId(userId);
        setShowEditModal(true);
      };
    
      const closeEditModal = () => {
        setSelectedUserId("");
        setShowEditModal(false);
        getUsers();
      };

      const getUsers = async () => {
        try {
            const response = await axios.get("https://meganet-api.vercel.app/users");
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const deleteUser = async (userId) => {
        const response = await axios.delete(`https://meganet-api.vercel.app/users/${userId}`)
        setData(response.data);
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError, user } = useSelector((state) => state.auth)


    useEffect(() => {
        dispatch(getMe());
    }, [dispatch])


    useEffect(() => {
        if (isError) {
            navigate("/")
        }
        if (user && user.role !== "full access") {
            navigate("/dashboard")
        }
    }, [isError, navigate])

    return (
        <section className='min-h-screen max-w-screen overflow-x-hidden'>
            <div className="hero h-screen w-full bg-gray-700 p-3 flex items-center justify-center">
                <div className="back-to-dashboard top-0 left-0 absolute">
                    <Link to={"/dashboard"} className="flex items-center gap-2 p-2 text-gray-300"><RiArrowGoBackFill/>Kembali ke Dashboard</Link>
                </div>
                {showAddModal && <ModalAddUsers onSubmitSuccess={
                    async () =>  {
                    await getUsers();
                    closeAddModal();
                    }
                } onClose={closeAddModal} />}

                {showEditModal && <ModalEditUser userId={selectedUserId} onSubmitSuccess={
                    async () =>  {
                    await getUsers();
                    closeAddModal();
                    }
                } onClose={closeEditModal} />}
                <div className=" w-[80%] mt-8 rounded-tl-md rounded-tr-md bg-gray-800 rounded-md">
                    <div className="title w-full bg-gradient-to-b from-green-500 to-green-600 p-2 mb-4 rounded-tl-md rounded-tr-md">
                        <h1 className="text-white font-semibold">Data User</h1>
                    </div>
                    <div className="box-container flex gap-2 items-end px-3 py-2">
                        <div className="left grid grid-cols-2 md:grid-cols-2 gap-2 w-[60%] md:flex md:items-end">
                            <div className="button-tambah-wrapper bg-green-600 hover:bg-green-700 rounded">
                                <button onClick={openAddModal} className="tambah flex items-center gap-1 p-2 text-white text-[10px] w-full">
                                    <AiFillPlusCircle color="white" />
                                    TAMBAH AKUN
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-x-auto mt-3 md:mt-8 px-">
                        <table className="min-w-full divide-y divide-gray-500">
                            <thead>
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ">
                                        Index
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Nama
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Username
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Role
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Edit
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Hapus
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    data.map((item, index) => (
                                        <tr key={item.uuid}>
                                            <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                                                {index + 1}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                                                {item.nama}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                                                {item.username}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                                                {item.role}
                                            </td>
                                            <td className="px-4 py-2">
                                                <button onClick ={() => openEditModal(item.uuid)} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-4 rounded">
                                                    Edit
                                                </button>
                                            </td>
                                            <td className="px-4 py-2">
                                                <button onClick={() => deleteUser(item.uuid)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded">
                                                    Hapus
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default User;
