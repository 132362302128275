import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ModalExportMonth = ({ children, onClose }) => {
  return (
    <div className="modal fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-600 bg-opacity-75">
      <div className="modal-content bg-gray-800 rounded p-4 flex flex-col gap-2">
        <div className="title-close-button flex justify-between items-center">
          <h1 className='text-gray-300 font-semibold'>LAPORAN BULANAN</h1>
          <button onClick={onClose} className="text-gray-600">
            <AiOutlineCloseCircle className="w-5 h-5" />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}

export default ModalExportMonth