import React, {useEffect} from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LogOut, getMe, reset } from '../features/authSlice'

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user} = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(getMe());
  },[dispatch])

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/")
  }

  return (
    <div >
    <nav class="navbar bg-gray-800 w-screen px-6 py-4 flex items-center justify-between" role="navigation" aria-label="main navigation">
      <div class="navbar-brand w-full">
          <NavLink class="navbar-item" to={"/dashboard"}>
          <h1 className='text-2xl font-bold text-white md:text-4xl'>MEGANET</h1>
          </NavLink>
      </div>
      <div class="navbar-brand w-full flex gap-5 items-center justify-end md:pr-6">
          <NavLink class="navbar-item md:flex justify-end hidden" to={"/dashboard"}>
          <h2 className='text-base text-white md:text-base'>{user && user.nama}</h2>
          </NavLink>
          <div className="button-tambah-wrapper bg-red-600 hover:bg-red-700 px-6 py-3 rounded">
                <button onClick={logout} className='tambah flex items-center gap-1 text-white'>LOGOUT</button>
          </div>
      </div>
    </nav>
    </div>
  )
}

export default Navbar