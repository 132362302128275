import React, { useState } from 'react'
import axios from 'axios'
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ModalFormAddTransaksi = ({ onClose, onSubmitSuccess }) => {
    const [tanggal, setTanggal] = useState('');
    const [kategori, setKategori] = useState('');
    const [jenis, setJenis] = useState('');
    const [deskripsi, setDeskripsi] = useState('');
    const [quantity, setQuantity] = useState('');
    const [total, setTotal] = useState('');

    const formatRupiah = (value) => {
        // Konversi nilai ke dalam format rupiah dengan menambahkan titik sebagai pemisah ribuan
        const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return formattedValue;
        };
        
        const handleTotalChange = (e) => {
            const inputValue = e.target.value;
            const numericValue = inputValue.replace(/[^0-9]/g, ''); // Hanya menyimpan angka dan menghapus karakter lainnya
            setTotal(numericValue);
        };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formattedTanggal = new Date(tanggal).toISOString().split('T')[0];
        const lowercaseKategori = kategori.toLowerCase();
        // Lakukan pemrosesan atau validasi data di sini
        // Misalnya, memanggil fungsi untuk mengirim data ke backend menggunakan Axios POST
        try {
            const response = await axios.post('https://meganet-api.vercel.app/transaksi', {
                tanggal : formattedTanggal,
                kategori : lowercaseKategori,
                jenis,
                deskripsi,
                quantity,
                total: parseFloat(total.replace(/\./g, '').replace(',', '.')), // Hilangkan titik sebelum mengirim ke backend
            });
            // Kosongkan kembali nilai-nilai pada state
            setTanggal('');
            setKategori('');
            setJenis('');
            setDeskripsi('');
            setQuantity('');
            setTotal('');
            // Panggil fungsi onClose untuk menutup modal
            onSubmitSuccess();
            onClose();
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-600 bg-opacity-75">
            <div className="bg-gray-800 rounded p-4">
                <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-white">Tambah Transaksi</h2>
                    <button onClick={onClose} className="text-gray-600">
                    <AiOutlineCloseCircle className="w-5 h-5" />
                    </button>
                </div>
                <form onSubmit={handleFormSubmit} className="space-y-2 w-80">
                    <div>
                        <label className="block mb-1 text-gray-300">Tanggal:</label>
                        <input
                            type="date"
                            value={tanggal}
                            onChange={(e) => setTanggal(e.target.value)}
                            className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300"
                        />
                    </div>
                    <div>
                        <label className="block mb-1 text-gray-300">Kategori:</label>
                        <select value={kategori} onChange={(e) => setKategori(e.target.value)} className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300">
                            <option value="">Pilih Kategori</option>
                            <option value="pemasukkan">Pemasukkan</option>
                            <option value="pengeluaran">Pengeluaran</option>
                        </select>
                    </div>
                    <div>
                        <label className="block mb-1 text-gray-300">Jenis:</label>
                        <input type="text" value={jenis} onChange={(e) => setJenis(e.target.value)} className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300" />
                    </div>
                    <div>
                        <label className="block mb-1 text-gray-300">Deskripsi:</label>
                        <textarea value={deskripsi} onChange={(e) => setDeskripsi(e.target.value)} className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300" />
                    </div>
                    <div>
                        <label className="block mb-1 text-gray-300">Quantity:</label>
                        <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300" />
                    </div>
                    <div>
                        <label className="block mb-1 text-gray-300">Total:</label>
                        <input type="text"  value={formatRupiah(total)} onChange={handleTotalChange} inputMode='numeric' className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300" />
                    </div>
                    <div className="flex justify-end">
                        <button type="submit" className="bg-green-500 hover:bg-green-600 text-white rounded px-4 py-2">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalFormAddTransaksi