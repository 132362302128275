import React, {useState} from 'react'
import axios from 'axios';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ModalAddUsers = ({onClose, onSubmitSuccess}) => {
    const [nama, setNama] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [role, setRole] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('https://meganet-api.vercel.app/users', {
                nama,
                username,
                password,
                confPassword,
                role,
            });
            // Kosongkan kembali nilai-nilai pada state
            setNama('');
            setUsername('');
            setPassword('');
            setConfPassword('');
            setRole('');
            // Panggil fungsi onClose untuk menutup modal
            onSubmitSuccess();
            onClose();
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-600 bg-opacity-75">
            <div className="bg-gray-800 rounded p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold text-white">Tambah User</h2>
                    <button onClick={onClose} className="text-gray-600">
                        <AiOutlineCloseCircle className="w-5 h-5" />
                    </button>
                </div>
                <form onSubmit={handleFormSubmit} className="space-y-2 w-80">
                    <div>
                        <label className="block mb-1 text-gray-300">Nama:</label>
                        <input
                            type="text"
                            value={nama}
                            onChange={(e) => setNama(e.target.value)}
                            className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300"
                        />
                    </div>
                    <div>
                        <label className="block mb-1 text-gray-300">Username:</label>
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300" />
                    </div>
                    <div>
                        <label className="block mb-1 text-gray-300">Password:</label>
                        <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300" />
                    </div>
                    <div>
                        <label className="block mb-1 text-gray-300">Confirm Password:</label>
                        <input type="text" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300" />
                    </div>
                    <div>
                        <label className="block mb-1 text-gray-300">Role:</label>
                        <select value={role} onChange={(e) => setRole(e.target.value)} className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-700 text-gray-300">
                            <option value="">Pilih Role</option>
                            <option value="full access">Full Access</option>
                            <option value="keuangan">Keuangan</option>
                            <option value="investor">Investor</option>
                        </select>
                    </div>
                    <div className="flex justify-end">
                        <button type="submit" className="button-submit bg-green-500 hover:bg-green-600 text-white rounded px-4 py-2">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalAddUsers