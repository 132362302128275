import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { AiFillPlusCircle } from 'react-icons/ai'
import { IoExit } from 'react-icons/io5'
import { BsFillCalendarDayFill, BsFillCalendarMonthFill } from 'react-icons/bs'
import { MdCleaningServices, } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { getMe } from '../features/authSlice'
import axios from 'axios'
import ModalFormAddTransaksi from '../components/ModalFormAddTransaksi'
import ModalFormEditTransaksi from '../components/ModalFormEditTransaksi'
import ModalExportMonth from '../components/ModalExportMonth'
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Dashboard = () => {

  const currentDate = new Date();
  const defaultBulan = currentDate.getMonth() + 1; // Perlu ditambah 1 karena indeks bulan dimulai dari 0
  const defaultTahun = currentDate.getFullYear();

  const [transaksi, setTransaksi] = useState([]);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [bulan, setBulan] = useState(defaultBulan);
  const [year, setYear] = useState(defaultTahun);
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedTransaksiId, setSelectedTransaksiId] = useState("");
  const [showExportModal, setShowExportModal] = useState(false);
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');

  const handleStartMonthChange = (event) => {
    setStartMonth(event.target.value);
  };

  const handleEndMonthChange = (event) => {
    setEndMonth(event.target.value);
  };

  const handleStartYearChange = (event) => {
    setStartYear(event.target.value);
  };

  const handleEndYearChange = (event) => {
    setEndYear(event.target.value);
  };


  function formatRupiah(angka) {
    const formattedNumber = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    }).format(angka);

    const formattedWithoutRp = formattedNumber.replace('Rp', '');

    const formattedWithoutDecimal = formattedWithoutRp.replace(',00', '');

    return formattedWithoutDecimal;
  }

  const [dataHariIni, setDataHariIni] = useState({})
  const [dataBulanIni, setDataBulanIni] = useState({})

  useEffect(() => {
    getDataHariIni();
  }, []);

  useEffect(() => {
    getDataBulanIni();
  }, []);

  const getDataHariIni = async () => {
    try {
      const response = await axios.get('https://meganet-api.vercel.app/transaksi/pendapatan/hariini');
      const data = response.data;
      setDataHariIni(data);
    } catch (error) {
      console.error(error);
    }
  }

  const getDataBulanIni = async () => {
    try {
      const response = await axios.get('https://meganet-api.vercel.app/transaksi/pendapatan/bulanini');
      const data = response.data;
      setDataBulanIni(data);
    } catch (error) {
      console.error(error);
    }
  }

  const getTransaksi = async (pageNumber) => {
    try {
      const response = await axios.get(`https://meganet-api.vercel.app/transaksi?month=${bulan}&year=${year}&page=${pageNumber}&limit=${limit}`);
      setTransaksi(response.data.response);
      setTotalPages(Math.ceil(response.data.totalCount / limit));
    } catch (error) {
      console.log(error);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setTransaksi([]);
      setCurrentPage(newPage);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setTransaksi([]);
      setCurrentPage(newPage);
    }
  };

  const handlePageClick = (pageNumber) => {
    setTransaksi([]);
    setCurrentPage(pageNumber);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10)); // Mengubah nilai limit saat dropdown dipilih
  };

  const handleTahunChange = (event) => {
    setYear(event.target.value);
  };

  const handleBulanChange = (event) => {
    setBulan(event.target.value);
  };

  const deleteTransaksi = async (transaksiId) => {
    await axios.delete(`https://meganet-api.vercel.app/transaksi/${transaksiId}`);
    getTransaksi(currentPage)
    getDataBulanIni();
    getDataHariIni();
  }

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    getTransaksi(currentPage);
  };

  const openEditModal = (idTransaksi) => {
    setSelectedTransaksiId(idTransaksi);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setSelectedTransaksiId("");
    setShowEditModal(false);
  };

  const openExportMonthModal = () => {
    setShowExportModal(true);
  };

  const closeExportMonthModal = () => {
    setShowExportModal(false);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();

    const columns = [
      { header: 'No.', dataKey: 'no' },
      { header: 'Tanggal', dataKey: 'tanggal' },
      { header: 'Kategori', dataKey: 'kategori' },
      { header: 'Jenis', dataKey: 'jenis' },
      { header: 'Nama Pengguna', dataKey: 'namaPengguna' },
      { header: 'Deskripsi', dataKey: 'deskripsi' },
      { header: 'Quantity', dataKey: 'quantity' },
      { header: 'Total', dataKey: 'total' },
    ];

    const filteredTransaksi = transaksi.filter((item) => {
      const itemMonth = new Date(item.tanggal).getMonth();
      const itemYear = new Date(item.tanggal).getFullYear();
      const startMonthValue = parseInt(startMonth);
      const endMonthValue = parseInt(endMonth);
      const startYearValue = parseInt(startYear);
      const endYearValue = parseInt(endYear);

      if (startYearValue === endYearValue) {
        return (
          itemYear === startYearValue &&
          itemMonth >= startMonthValue &&
          itemMonth <= endMonthValue
        );
      } else if (startYearValue < endYearValue) {
        if (itemYear === startYearValue && itemMonth >= startMonthValue) {
          return true;
        } else if (itemYear === endYearValue && itemMonth <= endMonthValue) {
          return true;
        } else if (itemYear > startYearValue && itemYear < endYearValue) {
          return true;
        }
      }

      return false;
    });

    const data = filteredTransaksi.map((item, index) => ({
      no: index + 1,
      tanggal: new Date(item.tanggal).toLocaleDateString('id-ID'),
      kategori: item.kategori.charAt(0).toUpperCase() + item.kategori.slice(1),
      jenis: item.jenis,
      namaPengguna: item.userId.nama,
      deskripsi: item.deskripsi,
      quantity: item.quantity,
      total: item.total.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' }),
    }));

    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleDateString('id-ID', { year: 'numeric', month: '2-digit', day: '2-digit' })
      .replace(/\//g, '-');

    doc.autoTable(columns, data, { startY: 20 });
    doc.save(`Transaksi_${formattedDate}.pdf`);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth)

  useEffect(() => {
    getTransaksi(currentPage);
  }, [limit, bulan, year, currentPage]);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch])


  useEffect(() => {
    if (isError) {
      navigate("/")
    }
  }, [isError, navigate])

  const getPageNumbers = () => {
    const pageNumbers = [];
    const delta = 2;
    const left = currentPage - delta;
    const right = currentPage + delta + 1;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= left && i < right)) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  return (
    <section className='min-h-screen max-w-screen overflow-x-hidden'>
      <Navbar />
      <div className="hero h-full w-full bg-gray-700 p-3">
        <div className="container-informasi flex items-start flex-col gap-4">
          <div className="title">
            <h1 className='text-white font-semibold text-lg'> Transaksi Bulan Ini</h1>
          </div>
          <div className="informasi-pemasukkan-pengeluanran w-full flex items-center gap-3">
            <div className="box-pemasukkan w-full h-24 p-2 rounded-md flex flex-col justify-center bg-gradient-to-b from-green-400 to-green-600 text-white">
              <h1 className='nilai-pemasukkan text-base md:text-3xl font-semibold'>{dataHariIni && formatRupiah(dataBulanIni.totalPemasukkan)}</h1>
              <h2 className='nilai-pemasukkan text-xs md:text-xl'>Pemasukkan Bulan Ini</h2>
            </div>
            <div className="box-pengeluaran w-full h-24 p-2 rounded-md flex flex-col justify-center bg-gradient-to-b from-red-400 to-red-600 text-white">
              <h1 className='nilai-pemasukkan text-base md:text-3xl font-semibold'>{dataHariIni && formatRupiah(dataBulanIni.totalPengeluaran)}</h1>
              <h2 className='nilai-pemasukkan text-xs md:text-xl'>Pengeluaran Bulan Ini</h2>
            </div>
            <div className="box-pemasukkan w-full h-24 p-2 rounded-md flex flex-col justify-center bg-gradient-to-b from-blue-400 to-blue-600 text-white">
              <h1 className='nilai-pemasukkan text-base md:text-3xl font-semibold'>{dataHariIni && formatRupiah(dataBulanIni.pendapatanBulanIni)}</h1>
              <h2 className='nilai-pemasukkan text-xs md:text-xl'>Pendapatan Bulan Ini</h2>
            </div>
          </div>
          <div className="title">
            <h1 className='text-white font-semibold text-lg'> Transaksi Hari Ini</h1>
          </div>
          <div className="informasi-pemasukkan-pengeluanran w-full flex items-center gap-3">
            <div className="box-pemasukkan w-full h-24 p-2 rounded-md flex flex-col justify-center bg-gradient-to-b from-green-400 to-green-600 text-white">
              <h1 className='nilai-pemasukkan text-base md:text-3xl font-semibold'>{dataHariIni && formatRupiah(dataHariIni.totalPemasukkan)}</h1>
              <h2 className='nilai-pemasukkan text-xs md:text-xl'>Pemasukkan Hari ini</h2>
            </div>
            <div className="box-pengeluaran w-full h-24 p-2 rounded-md flex flex-col justify-center bg-gradient-to-b from-red-400 to-red-600 text-white">
              <h1 className='nilai-pemasukkan text-base md:text-3xl font-semibold'>{dataHariIni && formatRupiah(dataHariIni.totalPengeluaran)}</h1>
              <h2 className='nilai-pemasukkan text-xs md:text-xl'>Pengeluaran Hari ini</h2>
            </div>
            <div className="box-pemasukkan w-full h-24 p-2 rounded-md flex flex-col justify-center bg-gradient-to-b from-blue-400 to-blue-600 text-white">
              <h1 className='nilai-pemasukkan text-base md:text-3xl font-semibold'>{dataHariIni && formatRupiah(dataHariIni.pendapatanHariIni)}</h1>
              <h2 className='nilai-pemasukkan text-xs md:text-xl'>Pendapatan Hari ini</h2>
            </div>
          </div>
        </div>
        <div className=" w-full mt-8 rounded-tl-md rounded-tr-md bg-gray-800 rounded-md">
          <div className="title w-full bg-gradient-to-b from-green-500 to-green-600 p-2 mb-4 rounded-tl-md rounded-tr-md">
            <h1 className='text-white font-semibold'>Data Transaksi</h1>
          </div>
          <div className="box-container flex gap-2 items-end px-3 py-2">
            <div className="left grid grid-cols-2 md:grid-cols-2 gap-2 w-[60%] md:flex md:items-end">
              {showAddModal && <ModalFormAddTransaksi onSubmitSuccess={
                async () => {
                  await getTransaksi();
                  getDataBulanIni();
                  getDataHariIni();
                  closeAddModal();
                }
              } onClose={closeAddModal} />}

              {showExportModal && <ModalExportMonth onClose={closeExportMonthModal}>
                <label className='text-gray-500'>Dari Tanggal:</label>

                <div className="flex gap-4">
                  <select className='focus:outline-none rounded bg-transparent text-gray-500 px-3 py-1 border-2 border-gray-600' value={startMonth} onChange={handleStartMonthChange}>
                    <option value="">-- Pilih Bulan --</option>
                    <option className='bg-gray-800' value={1}>Januari</option>
                    <option className='bg-gray-800' value={2}>Februari</option>
                    <option className='bg-gray-800' value={3}>Maret</option>
                    <option className='bg-gray-800' value={4}>April</option>
                    <option className='bg-gray-800' value={5}>Mei</option>
                    <option className='bg-gray-800' value={6}>Juni</option>
                    <option className='bg-gray-800' value={7}>Juli</option>
                    <option className='bg-gray-800' value={8}>Agustus</option>
                    <option className='bg-gray-800' value={9}>September</option>
                    <option className='bg-gray-800' value={10}>Oktober</option>
                    <option className='bg-gray-800' value={11}>November</option>
                    <option className='bg-gray-800' value={12}>Desember</option>
                    {/* Tambahkan pilihan bulan lainnya */}
                  </select>
                  <select value={startYear} onChange={handleStartYearChange} id="tahun" className="focus:outline-none rounded bg-transparent text-gray-500 px-3 py-1 border-2 border-gray-600">
                    <option className='bg-gray-800' value={2022}>2022</option>
                    <option className='bg-gray-800' value={2023}>2023</option>
                    <option className='bg-gray-800' value={2024}>2024</option>
                  </select></div>
                <label className='text-gray-500'>Sampai Tanggal:</label>
                <div className="flex gap-4">
                  <select className='focus:outline-none rounded bg-transparent text-gray-500 px-3 py-1 border-2 border-gray-600' value={endMonth} onChange={handleEndMonthChange}>
                    <option value="">-- Pilih Bulan --</option>
                    <option className='bg-gray-800' value={1}>Januari</option>
                    <option className='bg-gray-800' value={2}>Februari</option>
                    <option className='bg-gray-800' value={3}>Maret</option>
                    <option className='bg-gray-800' value={4}>April</option>
                    <option className='bg-gray-800' value={5}>Mei</option>
                    <option className='bg-gray-800' value={6}>Juni</option>
                    <option className='bg-gray-800' value={7}>Juli</option>
                    <option className='bg-gray-800' value={8}>Agustus</option>
                    <option className='bg-gray-800' value={9}>September</option>
                    <option className='bg-gray-800' value={10}>Oktober</option>
                    <option className='bg-gray-800' value={11}>November</option>
                    <option className='bg-gray-800' value={12}>Desember</option>
                    {/* Tambahkan pilihan bulan lainnya */}
                  </select>

                  <select value={endYear} onChange={handleEndYearChange} id="tahun" className="focus:outline-none rounded bg-transparent text-gray-500 px-3 py-1 border-2 border-gray-600">
                    <option className='bg-gray-800' value={2022}>2022</option>
                    <option className='bg-gray-800' value={2023}>2023</option>
                    <option className='bg-gray-800' value={2024}>2024</option>
                  </select>
                </div>
                <button className='bg-green-600 p-2 mt-4 rounded font-semibold text-white' onClick={exportToPDF}>Export ke PDF</button>
              </ModalExportMonth>
              }



              {user && (user.role === "full access" || user.role === "keuangan") && (
                <div className="button-tambah-wrapper bg-green-600 hover:bg-green-700 rounded">
                  <button onClick={openAddModal} className='tambah flex items-center gap-1 p-2 text-white text-[10px] w-full'><AiFillPlusCircle color='white' />TAMBAH</button>
                </div>
              )}

              <div className="button-tambah-wrapper bg-blue-600 hover:bg-blue-700 rounded">
                <button onClick={exportToPDF} className='Laporan-harian flex items-center gap-1 p-2 text-white text-[10px] w-full'><BsFillCalendarDayFill color='white' />LAP.HARIAN</button>
              </div>
              <div className="button-tambah-wrapper bg-cyan-600 hover:bg-cyan-700 rounded">
                <button onClick={openExportMonthModal} className='laporan bulanan flex items-center gap-1 p-2 text-white text-[10px] w-full'><BsFillCalendarMonthFill color='white' />LAP.BULANAN</button>
              </div>
              {user && user.role === 'full access' && (
                <div className="button-tambah-akun bg-green-600 hover:bg-green-700 rounded">
                  <Link to={"/user"} className='tambah flex items-center gap-1 p-2 text-white text-[10px] w-full'><AiFillPlusCircle color='white' />TAMBAH AKUN</Link>
                </div>
              )}
              {user && user.role === 'full access' && (
              <div className="button-tambah-wrapper bg-red-600 hover:bg-red-700 rounded">
                <button className='kosongkan flex items-center gap-1 p-2 text-white text-[10px] w-full'><MdCleaningServices color='white' />KOSONGKAN</button>
              </div>
              )}
            </div>
            <div className="right flex flex-col gap-2 w-[45%] md:flex-row md:items-center md:justify-end">
              <h1 className='text-white font-semibold '>Filter:</h1>
              <div>
                <select value={bulan} onChange={(event) => { handleBulanChange(event); getTransaksi(); }} id="bulan" className="px-3 py-2 rounded outline-none text-xs w-full md:w-36 bg-green-600 text-white">
                  <option className='bg-gray-800' value={1}>Januari</option>
                  <option className='bg-gray-800' value={2}>Februari</option>
                  <option className='bg-gray-800' value={3}>Maret</option>
                  <option className='bg-gray-800' value={4}>April</option>
                  <option className='bg-gray-800' value={5}>Mei</option>
                  <option className='bg-gray-800' value={6}>Juni</option>
                  <option className='bg-gray-800' value={7}>Juli</option>
                  <option className='bg-gray-800' value={8}>Agustus</option>
                  <option className='bg-gray-800' value={9}>September</option>
                  <option className='bg-gray-800' value={10}>Oktober</option>
                  <option className='bg-gray-800' value={11}>November</option>
                  <option className='bg-gray-800' value={12}>Desember</option>
                </select>
              </div>
              <div>
                <select value={year} onChange={(event) => { handleTahunChange(event); getTransaksi(); }} id="tahun" className="px-3 py-2 rounded outline-none text-xs w-full md:w-24 bg-green-600 text-white">
                  <option className='bg-gray-800' value={2022}>2022</option>
                  <option className='bg-gray-800' value={2023}>2023</option>
                  <option className='bg-gray-800' value={2024}>2024</option>
                </select>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-3 md:mt-8 px-">
            <div>
            </div>
            <div className='flex flex-col items-start justify-start md:flex-row md:items-center md:gap-4 pl-3'>
              <label htmlFor="limitDropdown" className='text-gray-300 '>Jumlah Data: </label>
              <select id="limitDropdown" className='px-2 py-2 rounded outline-none text-xs md:text-base border-2 border-gray-700 bg-transparent text-gray-300' value={limit} onChange={handleLimitChange}>
                <option className='bg-gray-800' value={10}>10</option>
                <option className='bg-gray-800' value={20}>20</option>
                <option className='bg-gray-800' value={50}>50</option>
              </select>
            </div>
            <table className="min-w-full divide-y divide-gray-500">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ">Index</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tanggal</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Kategori</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Jenis</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Deskripsi</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Qty</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                  {user && user.role === 'full access' && (
                    <>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Edit</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hapus</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {transaksi && transaksi.length > 0 ? (
                  transaksi.map((item, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-300">{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-300">{new Date(item.tanggal).toLocaleDateString('id-ID')}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <h1 className={`text-center py-1 px-2 rounded whitespace-nowrap text-white ${item.kategori === 'pemasukkan' ? 'bg-green-500' : 'bg-red-500'}`}>
                          {item.kategori.charAt(0).toUpperCase() + item.kategori.slice(1)}
                        </h1>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-300">{item.jenis}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-300">{item.userId.nama}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-300">{item.deskripsi}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-300">{item.quantity}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-300">{item.total.toLocaleString("id-ID", { style: "currency", currency: "IDR" })}</td>
                      {user && user.role === 'full access' && (
                        <>
                          <td className="px-4 py-2">
                            <button onClick={() => openEditModal(item.uuid)} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-4 rounded">
                              Edit
                            </button>
                          </td>
                          <td className="px-4 py-2">
                            <button onClick={() => deleteTransaksi(item.uuid)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded">
                              Hapus
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="px-6 py-4 text-center text-gray-400" colSpan="10">Tidak Ada Transaksi</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="flex justify-between mt-4 px-6 py-4 gap-4">
              <div className="inline-flex rounded-md shadow-sm">
                <div className=" py-2 whitespace-nowrap">
                  <h1 onClick={() => console.log(transaksi)} className='text-white text-xs md:text-base'>Showing {Math.min((limit * 1), transaksi.length)} to {Math.min((limit * 1), transaksi.length)} of {transaksi.length} entries</h1>
                </div>
              </div>
              <div className="inline-flex rounded-md shadow-sm">
                <button
                  onClick={handlePreviousClick}
                  disabled={currentPage === 1}
                  className="px-2 py-1 md:px-3  md:py-2 border border-gray-500 text-sm leading-5 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed rounded-tl rounded-bl hover:bg-blue-600"
                >
                  Previous
                </button>
                {getPageNumbers().map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageClick(page)}
                    disabled={currentPage === page}
                    className={`px-2 py-1 md:px-3  md:py-2 border border-gray-500 text-sm leading-5 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-600 ${currentPage === page ? 'bg-blue-600' : ''
                      }`}
                  >
                    {page}
                  </button>
                ))}
                <button
                  onClick={handleNextClick}
                  disabled={currentPage === totalPages}
                  className="px-2 py-1 md:px-3  md:py-2 border border-gray-500 text-sm leading-5 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed rounded-tr rounded-br hover:bg-blue-600"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEditModal && <ModalFormEditTransaksi transaksiId={selectedTransaksiId} onSubmitSuccess={
        () => {
          getTransaksi();
          getDataBulanIni();
          getDataHariIni();
          closeAddModal();
        }
      } onClose={closeEditModal} />}
    </section>
  )
}

export default Dashboard