import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, reset } from "../features/authSlice";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {user, isError, isSuccess, isLoading, message} = useSelector(
        (state) => state.auth
    );

    useEffect(() => {
    if (user || isSuccess) {
            navigate("/dashboard");
        }
        dispatch(reset());
    }, [user, isSuccess, dispatch, navigate]);
    

    const Auth = (e) => {
        e.preventDefault();
        dispatch(LoginUser({username, password}));
    }

    return (
        <section className="hero h-screen w-screen bg-gray-700">
            <div className="column h-full w-full flex flex-col items-center justify-center">
                <form onSubmit={Auth} className="box p-3 rounded-md shadow-md w-80 gap-4 flex flex-col bg-gray-800">
                    <div className="field">
                        <h1 className="text-2xl font-bold text-white">MEGANET</h1>
                        {isError && <p className="text-xs text-red-600">{message}</p>}
                    </div>
                    <div className="field">
                        <label className="label text-white">Username</label>
                        <div className="control">
                            <input
                                type="text"
                                className="input w-full px-2 rounded py-1"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label text-white">Password</label>
                        <div className="control ">
                            <input
                                type="Password"
                                className="input w-full px-2 rounded py-1"
                                placeholder="******"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <button type="submit" className="button bg-green-500 text-white w-full rounded-md py-2">
                            {isLoading ? "Loading..." : "Login"}
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Login;
